import validate from 'jquery-validation'
import owlCarousel from 'owl.carousel'

import {getCookie, setCookie, deleteCookie} from './components/cookie-utils'

$(document).ready(function() {

	function initStoriesSlider() {
		const $sliderContainer = $(".stories__slider")
		const slider = $sliderContainer.owlCarousel({
			items:1,
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 2,
					margin: 40
				}
			}
		});
		$('.stories__slider-btn--prev').on('click', function(event) {
			slider.trigger('prev.owl.carousel');
		});
		$('.stories__slider-btn--next').on('click', function(event) {
			slider.trigger('next.owl.carousel');
		});
	}

	function initSimSlider() {
		const $sliderContainer = $(".sim__slider")
		const slider = $sliderContainer.owlCarousel({
			items:1,
			responsive: {
				0: {
					items: 2,
					margin: 5,
					height: '100%'
				},
				600: {
					items: 4,
					margin: 33,
					height: 417
				}
			}
		});
		$('.sim__slider-btn--prev').on('click', function(event) {
			slider.trigger('prev.owl.carousel');
		});
		$('.sim__slider-btn--next').on('click', function(event) {
			slider.trigger('next.owl.carousel');
		});
	}

	function initKnowSlider() {
		const $sliderContainer = $(".know__slider")
		const slider = $sliderContainer.owlCarousel({
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 3,
					margin: 74
				}
			}
		});
		$('.know__slider-btn--prev').on('click', function(event) {
			slider.trigger('prev.owl.carousel');
		});
		$('.know__slider-btn--next').on('click', function(event) {
			slider.trigger('next.owl.carousel');
		});
	}

	function initVideo() {
		const $block = $('[data-video]')
		const videoId = $block.data('video')
		const $btn = $block.find('[data-btn]')
		const $previewPic = $block.find('data-preview')
		const $iframe = `
		<iframe allow="autoplay; allowfullscreen"  src="https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0" class="support__video-iframe"></iframe>
		`
		$btn.on('click', () => {
			$previewPic.hide()
			$block.html($iframe)
		})

	}

	function initSmoothScroll() {
		const $refs = $('[data-scroll-to]')

		$refs.on('click', function (evt) {
			evt.preventDefault()
			const $ref = $(this)
			const elId = $ref.data('scroll-to')
			$([document.documentElement, document.body]).animate({
				scrollTop: $(`#${elId}`).offset().top
			}, 1000);

		})
	}



	function init() {
		initStoriesSlider()
		initSimSlider()
		initKnowSlider()
		initVideo()
		initSmoothScroll()
	}

	init()

});
